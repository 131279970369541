<template>
  <div class="pa-3">
    <div class="mb-3">
      <v-btn small fab depressed color="green" dark @click="getBack">
        <v-icon>mdi-reply</v-icon>
      </v-btn>
    </div>
    <section>
      <h1 class="text-center grey--text">Upload Data Report Scholars</h1>
    </section>
    <div class="d-flex justify-center my-3">
      <v-card class="temp_dz _80w">
        <v-overlay absolute color="white" dark opacity="1" :value="overlay">
          <div class="d-flex align-center" v-if="loading">
            <v-progress-circular
              indeterminate
              size="30"
              color="primary"
              class="mr-3"
            ></v-progress-circular>
            <p class="black--text ma-0">Uploading...</p>
          </div>
          <div
            class="black--text d-flex flex-column align-center"
            v-if="errMsg"
          >
            <div class="d-flex justify-center black--text mb-5">
              <img
                src="../../../assets/img/errorImportReport.png"
                height="130px"
                alt=""
              />
            </div>
            <div>
              <div v-if="errMsg.message.row" class="d-flex mb-3">
                <div class="mr-3">
                  <v-icon color="black">mdi-information</v-icon>
                </div>
                <div>
                  <p class="ma-0">Error In Row {{ errMsg.message.row }}</p>
                  <p class="ma-0">{{ errMsg.message.message }}</p>
                </div>
              </div>
              <div v-else class="d-flex align-center mb-3">
                <div class="mr-3">
                  <v-icon color="black" class="">mdi-close-circle</v-icon>
                </div>
                <div>
                  {{ errMsg.message }}
                </div>
              </div>
            </div>
            <v-btn @click="closeOverlay" depressed color="#59919E" rounded
              >Try Again</v-btn
            >
          </div>
          <div v-if="resolve">
            <div class="d-flex justify-center black--text mb-5">
              <img
                src="../../../assets/img/succesImportReport.png"
                height="130px"
                alt=""
              />
            </div>
            <div class="d-flex justify-center black--text mb-5">
              <div>
                <v-icon color="black" class="mr-2">mdi-check-circle</v-icon>
                <b>
                  {{ resolve.message }}
                </b>
              </div>
            </div>
            <div class="d-flex justify-center">
              <v-btn
                @click="closeOverlay"
                class="mr-3 text-capitalize"
                depressed
                color="#59919E"
                rounded
                >Import Again</v-btn
              >
              <v-btn
                @click="toSummary"
                class="text-capitalize"
                depressed
                color="#59919E"
                rounded
                outlined
                >Back To Report Summary</v-btn
              >
            </div>
          </div>
        </v-overlay>
        <vue-dropzone
          ref="myVueDropzone"
          id="dropzone"
          :useCustomSlot="true"
          :options="dropzoneOptions"
          @vdropzone-file-added="uploadFile"
        >
          <div>
            <img
              src="../../../assets/img/dragCreateReport.png"
              height="130px"
              alt=""
            />
            <h3 class="dropzone-custom-title">Drag and drop to upload file!</h3>
            <div class="subtitle">
              ...or click to select a file from your computer
            </div>
          </div>
        </vue-dropzone>
      </v-card>
    </div>
    <v-divider></v-divider>
    <div class="my-3">
      <p class="text-center grey--text ma-0">Don't have a template yet?</p>
      <div class="d-flex justify-center align-center">
        <h3 class="text-center grey--text">Download Template Here</h3>
      </div>
      <div class="d-flex justify-center align-center my-1">
        <v-btn
          dark
          rounded
          color="#59919E"
          :href="`${env.replace('/api', '/')}template/input_score_scholar_tf_${route}.xlsx`"
        >
          <v-icon class="mr-2">mdi-arrow-down</v-icon> {{ route }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { mapState } from "vuex";

export default {
  name: "dropzone",
  components: {
    vueDropzone: vue2Dropzone,
  },
  computed: {
    ...mapState({
      env: state => state.env,
    }),
  },
  data() {
    return {
      route: null,
      overlay: false,
      loading: false,
      errMsg: null,
      resolve: null,
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        acceptedFiles:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        uploadMultiple: false,
        previewTemplate: this.template(),
      },
    };
  },

  mounted() {
    //   this.drop();
    this.route = this.$route.params.periode;
  },
  methods: {
    getBack() {
      this.$router.go(-1);
    },
    toSummary() {
      this.$router.push("/list/report");
      this.overlay = false;
      this.$refs.myVueDropzone.removeAllFiles();
    },
    closeOverlay() {
      this.$refs.myVueDropzone.removeAllFiles();
      this.resolve = null;
      this.overlay = false;
      this.errMsg = "";
    },
    uploadFile(e) {
      this.overlay = true;
      this.loading = true;
      let file = new FormData();
      file.append("file", e);
      let data = {
        file: file,
        periode: this.route,
      };
      this.$store
        .dispatch("report/importReport", data)
        .then((data) => {
          this.loading = false;
          this.resolve = data;
          this.Swal("success", data.message);
          this.$refs.myVueDropzone.removeAllFiles();
        })
        .catch((err) => {
          this.$refs.myVueDropzone.removeAllFiles();
          this.errMsg = err;
          this.loading = false;
          console.log(err);
        });
    },
    Swal(type, msg) {
      Swal.fire({
        icon: type,
        title: msg,
        showConfirmButton: false,
        position: "top",
        toast: true,
        timer: 5000,
      });
    },
    template: function () {
      return `<div class="dz-preview dz-file-preview">
                <div class="dz-details" style="display:none;">
                    <div class="dz-size"><span data-dz-size></span></div>
                    <div class="dz-filename"><span data-dz-name></span></div>
                </div>
                <div class="dz-progress" style="display:none;"><span class="dz-upload" data-dz-uploadprogress></span></div>
            </div>
        `;
    },
  },
};
</script>

<style scoped>
#dropzone {
  display: flex;
  justify-content: center;
  border-radius: 10px;
  color: #777;
  border: 1px dashed;
  transition: background-color 0.2s linear;
  min-height: 250px;
  width: 100%;
}

.dropzone-custom-title {
  margin-top: 0;
  color: #59919e;
}
.primary-color {
  color: #59919e;
}
.subtitle {
  color: #314b5f;
}
#dropzone .dz-preview {
  display: none;
  overflow: hidden;
}

.temp_dz {
  background: white !important;
  padding: 20px !important;
  border-radius: 20px !important;
  -webkit-box-shadow: 0px 10px 21px 11px rgba(3, 169, 244, 0.07) !important;
  -moz-box-shadow: 0px 10px 21px 11px rgba(3, 169, 244, 0.07) !important;
  box-shadow: 0px 10px 21px 11px rgba(3, 169, 244, 0.07) !important;
}
</style>
